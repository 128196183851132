import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import Layout from "./src/components/Layout";
import "./src/styles/global.css"

export const onRouteUpdate = () => {
}
// export { wrapRootElement } from './src/apollo/provider';

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element, props }) => {
  return (
    <Layout {...props}>
      {element}
    </Layout>
  )
}
