import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import FooterNav from "./FooterNav";
import Vk from '../../images/svg/vk_icon.svg'

const Footer = () => {
  const { allStrapiPage } = useStaticQuery(graphql`
		query {
			allStrapiPage(filter: {showFooter: {eq: true}}) {
				edges {
					node {
						pageHeader
						link
						categories {
							name
							catId
						}
						cat {
							slug
						}
					}
				}
			}
		}
  `)

  const links = allStrapiPage.edges.map(it => it.node)

  const allLinks = links.reduce((acc, rec) => {
    const cat = rec.categories[0].name
    if (!acc[cat]) {
      acc[cat] = []
    }
    return { ...acc, [cat]: [...acc[cat], rec] }
  }, [])

  return (
    <footer className="footer pt-16 pb-1 font-Montserrat bg-amber-500 bg-gradient-to-r from-pink-100 to-amber-200">
      <div className="mx-auto container px-4 xl:px-0">
        <div className="grid md:grid-cols-12 gap-2 border-b border-gray-300 pb-4">
          <div className="col-span-3">
            <h2>Стоматология <span className="underline underline-offset-8 decoration-amber-400 decoration-4">
                happy clinic</span>
            </h2>
            <h3 className="text-gray-500">Мы ухаживаем не только за вашими зубами, но и за вашей улыбкой. </h3>
            <div className="text-gray-500 text-sm">
              <div>Наш адрес: <span>г. Казань, Сибирский тракт, 3</span></div>
              <div>e-mail: <span>info@happy-clinic.ru</span></div>
            </div>
            <div className="text-gray-500 text-sm">Мы в социальных сетях
              <div className="py-4">
                <div>
                  <a href="https://vk.com/happy_clinic" target="_blank" title="Стоматология happy clinic">
                  <Vk className="w-6 h-6"/></a>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block col-span-9 pl-12">
            <div className="grid grid-cols-6 nav">
              <FooterNav links={allLinks}/>
            </div>
          </div>
        </div>
        <div className="hidden lg:block max-w-5xl mx-auto text-center py-4 text-sm text-gray-500">
          ООО "Хэппи Клиник". 420029, г. Казань, Сибирский тракт, 3. Данный сайт
          носит информационный характер и не является публичной офертой,
          определяемой положениями Статьи 437 (2) ГК РФ. Указанные цены на сайте
          приведены как справочная информация и могут быть изменены в любое
          время без предупреждения. Для получения подробной информации о
          стоимости, сроках и условиях просьба обращаться по телефонам клиники.
        </div>
        <div className="text-center py-6 ">
          <div className="font-bold text-xl">
            ИМЕЮТСЯ ПРОТИВОПОКАЗАНИЯ. НЕОБХОДИМА КОНСУЛЬТАЦИЯ СПЕЦИАЛИСТА
          </div>
          <div className="text-gray-500 text-sm ">
            <span className="font-semibold">Внимание 18+</span> Информация,
            представленная на сайте, не может быть использована для постановки
            диагноза, назначения и не заменяет прием врача.
          </div>
        </div>
        {/*© {new Date().getFullYear()}, happy clinic*/}
      </div>
    </footer>
  );
}

export default Footer