import React from "react";
import { Link } from "gatsby";

const FooterNav = ({ links }) => {
  return <>{Object.entries(links).map(([cat, pages]) => {
    let colSpan: string;

    // @ts-ignore
    if (pages.length < 5) {
      colSpan = ""
    }
    // @ts-ignore
    if (pages.length > 5) {
      colSpan = "col-span-2"
    }
    // @ts-ignore
    if (pages.length > 9) {
      colSpan = "col-span-3"
    }

    return <div key={cat} className={colSpan}>
      <div>{cat}</div>
      <div>
        { // @ts-ignore
          pages.map(({ pageHeader, link, categories, cat }) => {
            const category = categories[0].catId
            const url = cat?.slug ? `/${category}/${cat.slug}/${link}` : `/${category}/${link}`
            return <span key={pageHeader}>
              <Link to={url} title={pageHeader}>{pageHeader}</Link>
            </span>
          })
        }
      </div>
    </div>
  })}
  </>
}

export default FooterNav