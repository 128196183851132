import React from "react";
import TopHeader from "./Header/TopHeader";
import Footer from "./Header/Footer";

const Layout = ({ children, location }) => {
  return (<>
    <TopHeader/>
    <div className="bg-gray-100 lg:py-16">{children}</div>
    <Footer/>
  </>);
};

export default Layout;